import { Observer } from 'mobx-react-lite';
import React from 'react';

type PlanFeatureProps = {
  title: string;
  included?: boolean;
}

const PlanFeature: React.FC<PlanFeatureProps> = props => {
  return <Observer children={() => (
    <li className="PlanFeature">
      <p>{props.title}</p>
      {props.included?
      <svg width="10" height="8" viewBox="0 0 10 8"><use xlinkHref="#icon-check" /></svg>
      : null}
    </li>

  )}/>
}

export default PlanFeature;