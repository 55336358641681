import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { REACT_APP_ORIGIN, useGetReactAppOrigin } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useStore } from '../../utils/mobx.utils';
import BaseInput from '../BaseInput/BaseInput';
import CTAButton from '../CTAButton/CTAButton';
import PlanFeature from '../PlanFeature/PlanFeature';

type PlanCardProps = {
  title: string,
  description: string,
  price: number,
  // TODO: Bullets / Table
}

const PlanCard: React.FC<PlanCardProps> = props => {

  const s = useStore(() => ({
    form: {
      teamSize: 1,
    },

    get finalPrice() {
      const teamSize = s.form.teamSize * 1;
      let finalPrice = props.price;
      if (teamSize > 0) {
        finalPrice = props.price * s.form.teamSize;
      }
      return finalPrice;
    },

    get finalPriceValue() {
      const finalPriceString = this.finalPrice.toString().split('.');
      return finalPriceString[0];
    },

    get finalPriceValueDecimal() {
      const finalPriceString = this.finalPrice.toString().split('.');
      return finalPriceString[1];
    }
    
  }))

  const handleChange = () => {
    if (s.form.teamSize === 0) {
      s.form.teamSize = 1;
    }
  }

  useOnMount(() => {
    const d = reaction(
      () => s.form.teamSize,
      action(() => {
        // if(s.form.teamSize === 0) {

          // s.form.teamSize = Math.max(s.form.teamSize, 1);
        // }
      })
    )
    return d;
  })

  const appURL = useGetReactAppOrigin();

  return <Observer children={() => (
    <article className='PlanCard'>

      <header>
            <h2>{props.title}</h2>
            <div className='PlanTeamSize'>
              {/* <input id="team-size" type="number" onChange={()=>{console.log('t')}}/> */}
              {/* <form onChange={s.handleTeamSizeInputChange}> */}
              <form onChange={handleChange}>
              <BaseInput form={s.form} field="teamSize" Label="team size:" type="number"></BaseInput>
              {/* <label htmlFor="team-size">Team size</label> */}
              {/* <input id="team-size" type="number" onChange={s.handleTeamSizeInputChange}/> */}
              </form>
            </div>
            {/* <BaseInput type="number"></BaseInput> */}
            {/* <select name="" id="team--size-select">
              <option value="">Please choose an option</option>
            </select>
            */}
          </header>

          <p className="PlanPrice">
            <span className="PlanPrice__currency-symbol">€</span>
            <span className="PlanPriceValue">{s.finalPriceValue}</span>
            {
              s.finalPriceValueDecimal ? 
              <span className="PlanPriceValueDecimal">.{s.finalPriceValueDecimal}</span>

              :
              
              null
            }
            {/* <span className="PlanPriceDetail">Per user / per month</span> */}
          </p>
          {
          s.form.teamSize <= 1 ? 
          <p className='PlanPriceDetail'>per team member, per month, for life</p>
          : 
          <p className='PlanPriceDetail'>for {s.form.teamSize} team members, per month, for life</p>
          } 

          <p>
            {props.description}
          </p>

          <ul className='PlanFeatureList'>
            <PlanFeature title="TimeTracking" included={true}></PlanFeature>
            <PlanFeature title="Productivity Tooling" included={true}></PlanFeature>
            <PlanFeature title="Agile Management Software" included={true}></PlanFeature>
            <PlanFeature title="Statistical Analysis Software (coming soon)" included={true}></PlanFeature>
            <PlanFeature title="Chat Driven Communication – Text & Video (coming soon)" included={true}></PlanFeature>
            {/* <li>
              <p>Time Tracking</p>
            </li>
            <li>
              <p>Productivity Tooling</p>
            </li>
            <li>
              <p>Agile Management Software</p>
            </li>
            <li>
              <p>Statistical Analysis Software 
                <span>(coming soon)</span>
              </p>
            </li>
            <li>
              <p>Chat Driven Communication – Text &#38; Video 
                <span>(coming soon)</span>
              </p>
            </li> */}
          </ul>
          
          <CTAButton
            key={appURL}
            title="Get your free trial"
            path={`${appURL ?? REACT_APP_ORIGIN}/auth/register`}
          >
            Get your free trial
          </CTAButton>

          <div className='PlanSmallDetails'>
            <p>No credit card required.</p>
            <p>Start with a free trial period of 21 days.</p>
          </div>
          
    </article>
  )} />
}

export default PlanCard;