import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { getRandomNumericString } from '../../utils/random.utils';
import './BaseInput.scss';

type BaseInputProps<F = AnyObject> = {
  className?: string,
  name?: string,
  type?: HTMLInputElement['type'],
  Label?: ReactNode,
  form?: F,
  field?: StringKeyOf<F>,
  getter?: () => any,
  setter?: (v: any) => void,
  autoComplete?: HTMLInputElement['autocomplete'],
  autoFocus?: HTMLInputElement['autofocus'],
  autoCapitalize?: HTMLInputElement['autocapitalize'],
  optional?: boolean,
  required?: boolean,   
}

const BaseInput = <F extends {} = AnyObject>(props: React.PropsWithChildren<BaseInputProps<F>>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get name() {
      return p.name ?? p.field ?? getRandomNumericString();
    },
    get value() {
      return p.getter ? p.getter() : (p.form && p.field) ? p.form[p.field] : null;
    },
    set value(v) {
      p.setter ? p.setter(v) : (p.form && p.field) ? p.form[p.field] = v : void null;
    },
    handleChange: action((e: React.FormEvent<HTMLInputElement>) => {
      s.value = (e.target as HTMLInputElement).value;
    }),
    hasFocus: false,
    handleFocus: action(() => { s.hasFocus = true }),
    handleBlur: action(() => { s.hasFocus = false }),
  }))
  return <Observer children={() => (
    <div className={joinClassName('BaseInput', p.className, s.hasFocus && 'hasFocus')}>
      {p.Label && <label htmlFor={p.name}>
        {p.Label}
        {p.required && <em> * Required</em>}
        {p.optional && <em> (Optional)</em>}
      </label>}
      <input
        name={p.name}
        id={p.name}
        value={s.value}
        type={p.type ?? 'text'}
        onChange={s.handleChange}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        autoCapitalize={props.autoCapitalize}
        onFocus={s.handleFocus}
        onBlur={s.handleBlur}
        // min={p.min}
        // max={p.max}
        // placeholder={p.placeholder}
      />
    </div>
  )} />
}

export default BaseInput;