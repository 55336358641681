import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import PageSection from '../PageSection/PageSection';
import Widowless from '../Widowless/Widowless';
import './DefaultPageHeader.scss';

type DefaultPageHeaderProps = {
  id: string,
  title: string,
  titleFontSize?: 'md' | 'lg',
  className?: string,
  subtitle?: string,
  overline?: string,
  hideTitle?: boolean,
}

const DefaultPageHeader: React.FC<DefaultPageHeaderProps> = props => {
  const headingOneRef = useObservableRef<HTMLHeadingElement>();
  // useTextEnterSwoosh(props.hideTitle ? undefined : headingOneRef, { delay: 100 });
  return <Observer children={() => (
    <PageSection
      id={props.id}
      as="header"
      className={joinClassName('DefaultPageHeader', props.className, props.hideTitle && 'hideTitle')}
      observeVisibility
    >
      <main className="DefaultPageHeaderMain">
        {props.overline ? 
        <span className="DefaultPageHeaderOverline">{props.overline}</span> : null}
        <h1 className="DefaultPageHeaderTitle" data-size={props.titleFontSize ?? 'lg'} ref={headingOneRef}>{props.title}</h1>
        {props.subtitle && <Widowless className="DefaultPageHeaderSubtitle">{props.subtitle}</Widowless>}
      </main>
      
      <aside className="DefaultPageHeaderAside">
        {props.children && <div className="DefaultPageHeaderContent">
          {props.children}
          </div>
        }
      </aside>
    </PageSection>
  )} />
}

export default DefaultPageHeader;