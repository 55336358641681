import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import PageSection from '../PageSection/PageSection';
import './ArticleSection.scss';

type ArticleSectionProps = {
  id?: string,
  className?: string,
  noMaxWidth?: boolean
}

const ArticleSection: React.FC<ArticleSectionProps> = props => {
  return <Observer children={() => (
    <PageSection
      id={props.id ?? 'ArticleSection'}
      className={joinClassName('ArticleSection', props.className, props.noMaxWidth && 'noMaxWidth')}
      as="article"
    >
      <div className="ArticleSectionContent">
        { props.children }
      </div>
    </PageSection>
  )} />
}

export default ArticleSection;