import { Observer } from 'mobx-react-lite';
import React from 'react';
import ArticleSection from '../components/ArticleSection/ArticleSection';
import DefaultPageHeader from '../components/DefaultPageHeader/DefaultPageHeader';
import PageSection from '../components/PageSection/PageSection';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import PlanCard from '../components/PlanCard/PlanCard';
import { PageComponent } from '../types/gatsby.types';
import { ColorPalette } from '../utils/colors.utils';
import './pricing.scss';

const PagePricing: PageComponent = props => {
  return <Observer children={() => (
    <PageTemplate 
      {...props} // always include
      className="PagePricing"
      title="Pricing"
      description="Trackster Pricing"
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >
      <DefaultPageHeader
        id="PricingPageHeader"
        title="Get Trackster for less"
        subtitle="Exclusive offer for a limited time!"
      ></DefaultPageHeader>

      <ArticleSection>
        <p>To thank you for being one of the first trackster users we have an exclusive offer just for you.  For the first 6 months you can get trackster for the discounted offer of €5 per user per month. That’s a saving of around €100 for an average team.</p>
      </ArticleSection>

      <PageSection
      id="">

        <PlanCard
        title="Basic Plan"
        price="5.50"
        description="Secure your seat to ALL the current and future features of trackster for a fixed price forever.">
        </PlanCard>

      </PageSection>

      <ArticleSection>
        <p>
          Our pricing plan is simple - we have identified and included all the features that you need for a low price that is affordable for you. No confusing tiers, just one price for all the project management features that your company needs.
        </p>
        <p>
          Plus, we are giving you the opportunity to test out Trackster for free. 
          You can avail of the full functionality of Trackster for 21 days.
        </p>
      </ArticleSection>

    </PageTemplate>
  )} />
}

export default PagePricing;