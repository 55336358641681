import React from 'react';
import { preventWidows } from '../../utils/typography.utils';

type WidowlessProps = {
  as?: keyof HTMLElementTagNameMap,
  className?: string,
  children: string,
  onClick?: React.MouseEventHandler,
}

const Widowless: React.FC<WidowlessProps> = props => React.createElement(
  props.as ?? 'p', {
    ...props,
    children: preventWidows(props.children)
  }
)

export default Widowless;